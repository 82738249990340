import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Layout, SEO } from "../../components/structure";
import {
  Hero,
  Content,
  ExecutiveBio,
  Button,
  CallToAction,
} from "../../components/blocks";

import styled from "styled-components";
import { mq, brand, font } from "../../styles";

import english from "../../../content/translations/en-CA/about.json";
import french from "../../../content/translations/fr-CA/about.json";

const ExecLeadershipTeam = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-ELT.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIconBlue: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      execKenTile: file(base: { eq: "KenHarris.jpg" }) {
        ...BioTileImage
      }
      execKenPopup: file(base: { eq: "KenHarris.jpg" }) {
        ...BioPopupImage
      }
      execBrettTile: file(base: { eq: "BrettM.jpg" }) {
        ...BioTileImage
      }
      execBrettPopup: file(base: { eq: "BrettM.jpg" }) {
        ...BioPopupImage
      }
      execDanielleTile: file(base: { eq: "DanielleB.jpg" }) {
        ...BioTileImage
      }
      execDaniellePopup: file(base: { eq: "DanielleB.jpg" }) {
        ...BioPopupImage
      }
      execGioTile: file(base: { eq: "GioP.jpg" }) {
        ...BioTileImage
      }
      execGioPopup: file(base: { eq: "GioP.jpg" }) {
        ...BioPopupImage
      }
      execFadyTile: file(base: { eq: "FadyH.jpg" }) {
        ...BioTileImage
      }
      execFadyPopup: file(base: { eq: "FadyH.jpg" }) {
        ...BioPopupImage
      }
      execGeorgeTile: file(base: { eq: "GeorgeY.jpg" }) {
        ...BioTileImage
      }
      execGeorgePopup: file(base: { eq: "GeorgeY.jpg" }) {
        ...BioPopupImage
      }
      execSarahTile: file(base: { eq: "SarahB.jpg" }) {
        ...BioTileImage
      }
      execSarahPopup: file(base: { eq: "SarahB.jpg" }) {
        ...BioPopupImage
      }
      execJayTile: file(base: { eq: "JayM.jpg" }) {
        ...BioTileImage
      }
      execJayPopup: file(base: { eq: "JayM.jpg" }) {
        ...BioPopupImage
      }
    }
  `);

  const ExecutiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 35px 70px;
    width: 95%;
    max-width: 1400px;
    margin: auto;
    background: #fff;
    padding: 100px 0;
    @media screen and ${mq.maxMd} {
      display: block;
    }
  `;

  const ExecutiveIntro = styled.div`
    ${font.imports.sherika}

    width: 95%;
    max-width: 1400px;
    margin: 100px auto;
    @media screen and ${mq.maxMd} {
      text-align: center;
    }

    h4 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 48px;
      color: ${brand.colors.pg_blue};
    }

    h3 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 32px;
      color: #fff;
    }
  `;

  return (
    <Layout>
      <SEO
        title={"Executive Leadership Team | Plusgrade"}
        description={
          "Meet our Executive leadership team - the diverse group of people leading the way to make Plusgrade the global ancillary revenue powerhouse."
        }
        keywords={"leadership, ceo, executives"}
      />
      <Hero
        title={"Executive\nLeadership Team"}
        subtitle={lang.hero.subtitle}
        image={data["heroImg"]}
        imageStyle={{ objectPosition: "center bottom" }}
        fullBG
      />
      <Content contained>
        <ExecutiveIntro>
          <h4>Meet our Executive Leadership Team</h4>
          <h3>
            When it comes to achieving our mission to be the global ancillary
            revenue powerhouse, this team is leading the way. Learn about the
            diverse experience they bring to Plusgrade.
          </h3>
        </ExecutiveIntro>
      </Content>

      <Content whiteBG>
        <ExecutiveContainer>
          <ExecutiveBio
            name={"Ken Harris"}
            title={"Founder & CEO"}
            blurb={
              "Ken Harris is the Founder & CEO of Plusgrade, one of Canada's largest private technology companies. Over the past 15 years, he has led the company to empower over 200 travel suppliers across 60 countries in generating billions in new revenue and establishing Plusgrade as the global ancillary revenue powerhouse. Ken has achieved significant recognition across the tech industry, from the prestigious Deloitte Technology Leadership Award™ to being named one of Canada’s Top 40 Under 40™. Of course, Ken loves to travel, especially anywhere where he can have fun in or on the water."
            }
            profileIcon={data["execKenTile"]}
            profilePopup={data["execKenPopup"]}
            linkedin={"https://www.linkedin.com/in/kenharris/"}
          />

          <ExecutiveBio
            name={"Jay Malowney"}
            title={"President, Points"}
            blurb={
              "Jay Malowney is the President of the Points Business Unit. With 25 years in the loyalty industry, his expertise drives innovation and growth throughout the company.  Before joining forces with Plusgrade, Jay excelled as the Chief Commercial Officer at Points, managing commercial growth, marketing, relationship management, growth teams, and strategic partnerships. In his free time, you’ll either find Jay with his wife and three kids at a cottage or hiking, running, and mountain biking on the many trails around Ontario."
            }
            profileIcon={data["execJayTile"]}
            profilePopup={data["execJayPopup"]}
            linkedin={"https://www.linkedin.com/in/jay-malowney-3302693/"}
          />
          <ExecutiveBio
            name={"Brett Mellon"}
            title={"President, Solutions"}
            blurb={
              "Brett Mellon, as the President of the Solutions Business Unit, is an accomplished engineering professional with 30+ years of experience across five diverse industries. With a strong focus on tech in operations roles for over a decade, he is highly regarded for his exceptional problem-solving skills and comprehensive understanding of operations at all management levels. When he isn’t at work, Brett enjoys all things summer including golfing, cottaging, and spending quality time with family."
            }
            profileIcon={data["execBrettTile"]}
            profilePopup={data["execBrettPopup"]}
            linkedin={"https://www.linkedin.com/in/brett-mellon-99418615/"}
          />
          <ExecutiveBio
            name={"Sarah Bettencourt"}
            title={"Chief People Officer"}
            blurb={
              "Sarah Bettencourt is the Chief People Officer, where she leads all aspects of people and culture, including, performance management, succession planning, and strategic business insights. With a Master's in Organizational Effectiveness & Design and 20+ years of experience, she empowers teams across the company to own and drive a culture of engagement and high performance. For her exceptional contributions in business and people management, Sarah has been recognized as Female Business Leader of the Year and Female Executive Leader Award. Personally, Sarah loves all things family, traveling the world, and road biking."
            }
            profileIcon={data["execSarahTile"]}
            profilePopup={data["execSarahPopup"]}
            linkedin={
              "https://www.linkedin.com/in/sarah-bettencourt-mhrm-2bb5754/"
            }
          />
          <ExecutiveBio
            name={"Danielle Brown"}
            title={"Chief Marketing Officer"}
            blurb={
              "Danielle Brown is the Chief Marketing Officer, who oversees Data Science & Analytics, Brand & Content, and Partner, Product & Performance Marketing. She builds and leads happy, high-performance, profit-centric teams that drive growth. Danielle's expertise in change management, negotiation, and innovation spans various industries including loyalty, e-commerce, entertainment, and tech. An internationally sought-after speaker, she covers topics such as leadership and growth in both B2B and B2C businesses. In her spare time, Danielle is an avid curler and has a bit of a tattoo addiction."
            }
            profileIcon={data["execDanielleTile"]}
            profilePopup={data["execDaniellePopup"]}
            linkedin={"https://www.linkedin.com/in/danielle-brown-7aaa3427/"}
          />
          <ExecutiveBio
            name={"Giovanni Pizzoferrato"}
            title={"Chief Technology Officer"}
            blurb={
              "Giovanni (Gio) Pizzoferrato is the Chief Technology Officer at Plusgrade, leading advanced cloud, data, and software services development. With over 20 years of technology leadership experience across telecommunications, retail, supply chain, and finance industries, Gio is known for his strategic vision and innovation. He focuses on enhancing user experiences and operational efficiency while emphasizing mentorship to foster team growth. Recognized as a forward-thinking leader in cloud technology, Gio consistently pushes boundaries. His career includes executive roles at TELUS, Canadian Tire, Kinaxis, Manulife, and National Bank of Canada. Outside work, Gio enjoys traveling and cooking pizza."
            }
            profileIcon={data["execGioTile"]}
            profilePopup={data["execGioPopup"]}
            linkedin={"https://www.linkedin.com/in/giopizzo/"}
          />
          <ExecutiveBio
            name={"Fady Hammal"}
            title={"Senior Vice President and General Counsel"}
            blurb={
              "Fady Hammal leads Plusgrade’s legal, corporate governance, and compliance functions as SVP, General Counsel & Corporate Secretary. He also oversees the company's evolving risk management framework. With 15+ years of experience, Fady honed his expertise at Osler, Hoskin & Harcourt LLP and National Bank of Canada, focusing on M&A, corporate governance, information technology, partnerships, venture capital, capital markets, and financial services. He holds a Bachelor of Law (LLB) and Juris Doctor (JD) from Université de Montréal and is admitted to the Québec Bar. When not shaping Plusgrade’s legal landscape, he enjoys staying active, running, working out, and traveling with his family."
            }
            profileIcon={data["execFadyTile"]}
            profilePopup={data["execFadyPopup"]}
            linkedin={"https://www.linkedin.com/in/fady-hammal-93a815b0/"}
          />
          <ExecutiveBio
            name={"George Yao"}
            title={"Chief Financial Officer"}
            blurb={
              "George Yao is the Chief Financial Officer, overseeing global FP&A, accounting, tax, treasury, payments, strategy, corporate development, business operations, legal and compliance. With 20+ years of experience, George was formerly a Managing Director at Morgan Stanley and TD Securities. George has a proven track record of executing high-profile financing and M&A transactions. He holds an MBA from Rotman, an MSc in Computer Science, and a HBSc in Bioscience from Western University. As a strategic thinker, he continuously focuses on growth and efficiency. When possible, George loves a few hot laps on the racetracks. "
            }
            profileIcon={data["execGeorgeTile"]}
            profilePopup={data["execGeorgePopup"]}
            linkedin={"https://www.linkedin.com/in/georgeyao/"}
          />

          <div style={{ visibility: "hiddn", width: "250px" }}></div>
        </ExecutiveContainer>

        <CallToAction
          responsiveButton
          title={"Join our team"}
          subtitle={
            "We’re always looking for driven, creative people to join our team."
          }
        >
          <Button
            destination={"/careers/"}
            label={"See open roles"}
            icon={data["rightArrowIconBlue"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default ExecLeadershipTeam;
